import React, { useState } from "react";
import { Input } from "./ui/input";
import { Eye, EyeIcon, EyeOff } from "lucide-react";
import {
  EyeClosedIcon,
  EyeNoneIcon,
} from "@radix-ui/react-icons";

export default function EyePassword({
  value,
  setValue,
}: {
  value: string;
  setValue: (e: string) => void;
}) {
  const [inputType, setInputType] = useState("password");
  const [showEye, setShowEye] = useState(true);
  return (
    <div className="flex gap-2 border items-center w-full">
      <Input
        name="password"
        type={inputType}
        required
        placeholder="Password"
        className={`text-neutral-900 focus:outline-none w-full dark:text-neutral-200 rounded-none border-none w-full`}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <EyeIcon
        onClick={() => {
          setInputType("text");
          setShowEye(false);
        }}
        className={
          showEye ? "block mr-1 h-5 w-5" : "hidden"
        }
      />
      <EyeOff
        onClick={() => {
          setInputType("password");
          setShowEye(true);
        }}
        className={
          !showEye ? "block mr-1 h-5 w-5" : "hidden"
        }
      />
    </div>
  );
}
