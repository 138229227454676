import { Input } from "../../../src/components/ui/input";
import { Coupon } from "../../../src/lib/interfaces";
import { WooCommerce } from "../../../src/lib/api";
import React from "react";
import { useQuery } from "react-query";
import { Button } from "../../../src/components/ui/button";

export default function Coupons() {
  const [couponCode, setCouponCode] = React.useState("");
  const [discount, setDiscount] = React.useState("");
  const { data: coupons } = useQuery(
    ["api/Clients/Get_Follow_Clients"],
    async () => {
      const res: Coupon[] = await WooCommerce.get("coupons")
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });

      return res;
    }
  );

  function checkCode() {
    let amount: string = "";
    coupons?.map((coupon) => {
      if (coupon.code === couponCode.toLocaleLowerCase()) {
        amount = coupon.amount;
      }
      setDiscount(amount);
      return amount;
    });
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <h3>Promo Code</h3>
        <Input
          placeholder="Promo code"
          className="focus:outline-none sm:w-48 w-32 rounded-none"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />
        {couponCode !== "" && (
          <Button
            onClick={() => checkCode()}
            className="rounded-none"
          >
            Get Discount
          </Button>
        )}
      </div>
      {discount && (
        <p className="mt-5">You've got {discount}$</p>
      )}
    </div>
  );
}
