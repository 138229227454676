import React from "react";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import {
  CheckCheck,
  Layers2,
  PenIcon,
  PlusIcon,
} from "lucide-react";
import { category } from "../../../../lib/interfaces";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";
import { useQueryClient } from "react-query";

export default function CategoryDialog({
  category,
}: {
  category?: category;
}) {
  const [categoryName, setCategoryName] =
    React.useState<string>(category?.CategoryName || "");
  const [categoryNotes, setCategoryNotes] =
    React.useState<string>(category?.Notes || "");
  const reactQuery = useQueryClient();
  const newCategory: category = {
    CategoryID: category?.CategoryID || 0,
    CategoryName: categoryName,
    Notes: categoryNotes,
  };

  const saveCategory = async (category: category) => {
    try {
      const response = await fetch(
        `http://canadian-eye-center.com/api/Category/Save_Category`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            CategoryID: category.CategoryID || 0,
            CategoryName: categoryName,
            Notes: categoryNotes,
          }),
        }
      );
      const data = await response.json();
      console.log(data);
      reactQuery.refetchQueries([
        "api/Category/Get_Categories",
      ]);
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        {category ? (
          <Button
            className="flex justify-start gap-2"
            variant="ghost"
          >
            <PenIcon className="w-4 h-4 " />
          </Button>
        ) : (
          <Button className="flex justify-start gap-2">
            <PlusIcon className="w-4 h-4 " />
            Add Category
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] flex flex-col justify-start">
        {category ? (
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <Layers2 className="w-4 h-4" />
              Edit Category
            </DialogTitle>
            <DialogDescription>
              Edit category here. Click save when you're
              done.
            </DialogDescription>
          </DialogHeader>
        ) : (
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <Layers2 className="w-4 h-4" />
              Add Category
            </DialogTitle>
            <DialogDescription>
              Add category here. Click save when you're
              done.
            </DialogDescription>
          </DialogHeader>
        )}

        <div className="flex flex-col gap-2">
          <Input
            id="firstName"
            className="focus:outline-neutral-900 "
            value={categoryName}
            onChange={(e) =>
              setCategoryName(e.target.value)
            }
          />
          <Textarea
            id="lastName"
            className="col-span-3"
            value={categoryNotes}
            onChange={(e) =>
              setCategoryNotes(e.target.value)
            }
          />
        </div>
        <DialogFooter>
          <DialogClose>
            <Button
              className="flex justify-center items-center gap-2"
              onClick={() => saveCategory(newCategory)}
            >
              <CheckCheck className="w-4 h-4" />
              Save Category
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
