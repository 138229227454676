import React from "react";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import {
  CheckCheck,
  MonitorPlay,
  PenIcon,
  PlusIcon,
} from "lucide-react";
import {
  category,
  course,
} from "../../../../lib/interfaces";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { useQuery, useQueryClient } from "react-query";
export default function EditCourseDialog({
  course,
}: {
  course?: course;
}) {
  const [categoryId, setCategoryId] =
    React.useState<string>(
      String(course?.CATEGORY_ID) || ""
    );
  const [courseDescription, setCourseDescription] =
    React.useState<string>(course?.DESCRIPTION || "");
  const [courseTitle, setCourseTitle] =
    React.useState<string>(course?.COURSE_TITLE || "");
  const [coursePrice, setCoursePrice] =
    React.useState<string>(String(course?.PRICE) || "");
  const [courseDuration, setCourseDuration] =
    React.useState<string>(String(course?.DURATION) || "");

  const reactQuery = useQueryClient();
  const { data: categories } = useQuery(
    ["api/Category/Get_Categories"],
    async () => {
      const res = await fetch(
        `http://canadian-eye-center.com/api/Category/Get_Categories`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error: ${res.status}`);
      }
      const receivedCategories: category[] =
        await res.json();
      return receivedCategories;
    }
  );
  const newCourse: course = {
    COURSE_ID: course?.COURSE_ID || 0,
    COURSE_TITLE: courseTitle,
    DESCRIPTION: courseDescription,
    CATEGORY_ID: Number(categoryId),
    PRICE: Number(coursePrice),
    DURATION: Number(courseDuration),
  };

  const saveCourse = async (course: course) => {
    try {
      const response = await fetch(
        `http://canadian-eye-center.com/api/Course/Save_Course`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            COURSE_ID: course.COURSE_ID,
            COURSE_TITLE: course.COURSE_TITLE,
            CATEGORY_ID: course.CATEGORY_ID,
            PRICE: course.PRICE,
            DURATION: course.DURATION,
            DESCRIPTION: course.DESCRIPTION,
          }),
        }
      );
      const data = await response.json();
      console.log(data);
      reactQuery.refetchQueries(["api/Course/Get_Courses"]);
      // window.location.reload();
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        {course ? (
          <Button
            className="flex justify-start gap-2"
            variant="ghost"
          >
            <PenIcon className="w-4 h-4 " />
          </Button>
        ) : (
          <Button className="flex justify-start gap-2">
            <PlusIcon className="w-4 h-4 " />
            Add Course
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <MonitorPlay className="w-4 h-4" />
            Edit Course
          </DialogTitle>
          <DialogDescription>
            Edit course here. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col justify-between gap-2 items-center">
            <Input
              id="courseName"
              className="focus:outline-neutral-900  w-full"
              value={courseTitle || ""}
              onChange={(e) =>
                setCourseTitle(e.target.value)
              }
              placeholder="Course Name"
            />
            <Input
              id="courseName"
              className="focus:outline-neutral-900  w-full"
              type="number"
              min="0.1"
              step="0.1"
              value={coursePrice || ""}
              onChange={(e) =>
                setCoursePrice(e.target.value)
              }
              placeholder="Course Price"
            />
            <Input
              id="courseName"
              className="focus:outline-neutral-900  w-full"
              type="number"
              min="0.1"
              step="0.1"
              value={courseDuration || ""}
              onChange={(e) =>
                setCourseDuration(e.target.value)
              }
              placeholder="Course Duration"
            />
            <Select
              onValueChange={(e) => {
                setCategoryId(e);
              }}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a category" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Categories</SelectLabel>
                  {categories?.map((category: category) => (
                    <SelectItem
                      key={category.CategoryID}
                      value={String(category.CategoryID)}
                    >
                      {category.CategoryName}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <Textarea
              id="lastName"
              className="focus:outline-neutral-900  w-full"
              value={courseDescription || ""}
              onChange={(e) =>
                setCourseDescription(e.target.value)
              }
              placeholder="Course Description"
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              type="submit"
              className="flex justify-center items-center gap-2"
              onClick={() => saveCourse(newCourse)}
            >
              <CheckCheck className="w-4 h-4" /> Save Course
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
