import React from "react";
import { useQuery } from "react-query";
import { wooProduct } from "../products/productInterface";
import { WooCommerce } from "../../../src/lib/api";
import MyLoader from "../../../src/components/MyLoader";
import { Card } from "../../../src/components/ui/card";
import { Link } from "react-router-dom";
import AddToCart from "../../../src/components/addToCart";
import Wishlist from "../../../src/components/Wishlist";
import useWishlist from "../../../src/hooks/useWishlist";

export default function WishlistProduct({
  wishlist,
  productId,
}: {
  wishlist: any;
  productId: number;
}) {
  const [mainImgSrc, setMainImgSrc] = React.useState("");
  const { data: product, isLoading } = useQuery(
    ["singleProduct", productId],
    async () => {
      const res: wooProduct = WooCommerce.get(
        `products/${productId}`
      )
        .then((response: any) => {
          response.data.images.length > 0 &&
            setMainImgSrc(
              response.data.images[0].src || "/logo.png"
            );
          return response.data;
        })

        .catch((error: any) => {
          console.log("error getting product", error);
        });
      return res;
    }
  );
  return (
    <Card
      className={
        "flex justify-between gap-2 relative flex-col p-2 sm:w-64 h-full p-0 rounded-none w-full"
      }
    >
      <div className="absolute top-4 right-4">
        {product ? (
          <Wishlist
            wishlist={wishlist}
            productId={product.id}
            productName={product.name}
          />
        ) : (
          <></>
        )}
      </div>
      {isLoading ? (
        <div className="p-5">
          <MyLoader skeleton />
        </div>
      ) : (
        <Link
          to={`/product/${product?.categories[0]?.id}/${product?.categories[0]?.name}/${product?.id}/${product?.slug}`}
          className="flex justify-center items-center bg-white p-2"
        >
          <img
            src={mainImgSrc || "/logo.png"}
            alt={product?.name}
            className="sm:w-32 w-20"
          />
        </Link>
      )}
      <div className="flex flex-col gap-5 justify-between p-1 w-full">
        <div className="flex justify-between items-center gap-5">
          <h2 className="text-sm truncate">
            {product?.name}
          </h2>
          <h3 className="font-montserrat font-bold pr-1 sm:text-xl text-md ">
            {product ? product?.price + "$" : ""}
          </h3>
        </div>
        <AddToCart
          id={String(product?.id)}
          name={String(product?.name)}
          categoryId={Number(product?.categories[0]?.id)}
          categoryName={String(
            product?.categories[0]?.name
          )}
          quantity={"1"}
        />
      </div>
    </Card>
  );
}
