import React from "react";
import { useCookies } from "react-cookie";
import { ProfileData } from "../../lib/interfaces";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import {
  Avatar,
  AvatarFallback,
} from "../../components/ui/avatar";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import { useQuery, useQueryClient } from "react-query";
import MyLoader from "../../../src/components/MyLoader";
import UserOrders from "./userOrders";
import { WooCommerce, apiCall } from "../../../src/lib/api";
import { Skeleton } from "../../../src/components/ui/skeleton";
import BillingInfo from "./billingInfo";

export default function Profile() {
  const [cookies, removeCookie, deleteCookie] = useCookies([
    "userToken",
    "cartKey",
    "userId",
  ]);
  const nav = useNavigate();
  const reactQuery = useQueryClient();
  React.useEffect(() => {
    if (!cookies.userToken) {
      nav("/login");
    }
  }, []);

  async function getUserWishlistId() {
    try {
      const res = await fetch(
        `${apiCall}/wp-json/yith/wishlist/v1/wishlists/`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${cookies.userToken}`,
          },
        }
      );
      if (!res.ok) {
        throw new Error(
          `HTTP error:cant get wishlist ${res.status}`
        );
      }
      const data = await res.json();
      window.localStorage.setItem(
        "wishlist_id",
        data[0].id
      );
    } catch (err) {
      console.log("could not get wishlist id ", err);
    } finally {
      cookies.userToken &&
        reactQuery.refetchQueries(["LoggedInwishlist"]);
    }
  }
  React.useEffect(() => {
    cookies.userToken && getUserWishlistId();
  }, []);

  const SignOut = () => {
    deleteCookie("userToken");
    deleteCookie("cartKey");
    deleteCookie("userId");
    localStorage.removeItem("wishlist_id");
    localStorage.removeItem("wishlistArray");
    reactQuery.refetchQueries(["cartItems"]);
    reactQuery.refetchQueries(["homeProducts"]);
    reactQuery.refetchQueries(["wooProducts"]);
    reactQuery.refetchQueries(["LoggedInwishlist"]);
    nav("/");
  };

  const { data: billing } = useQuery(
    ["users/billing"],
    async () => {
      const res = await WooCommerce.get(
        `customers/${cookies.userId}`
      )
        .then((response: any) => {
          return response.data.billing;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });

      return res;
    },
    { enabled: !!cookies.userToken }
  );

  const { data: user, isLoading } = useQuery(
    ["users/me", cookies.userToken],
    async () => {
      const res = await fetch(
        `${apiCall}/wp-json/wp/v2/users/me?context=edit`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${cookies.userToken}`,
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error: ${res.status}`);
      }
      const data: ProfileData = await res.json();
      reactQuery.refetchQueries(["cartItems"]);
      reactQuery.refetchQueries(["homeProducts"]);
      reactQuery.refetchQueries(["wooProducts"]);
      cookies.userToken &&
        reactQuery.refetchQueries(["LoggedInwishlist"]);
      window.localStorage.setItem(
        "firstName",
        data.first_name
      );
      window.localStorage.setItem(
        "lastName",
        data.last_name
      );
      return data;
    },
    { enabled: !!cookies.userToken }
  );

  return (
    <div className="flex gap-5 sm:mt-32 mt-16 w-screen ">
      {!isLoading && !user ? (
        <MyLoader />
      ) : (
        <div className="flex gap-5 p-5 w-full flex-col sm:flex-row ">
          <div className="p-1 gap-5 sm:h-full flex flex-col justify-between w-full sm:w-[350px]">
            {!user ? (
              <div className="h-full flex justify-center items-center flex-col gap-2">
                <Skeleton className="w-24 h-2" />
                <Skeleton className="w-24 h-2" />
                <Skeleton className="w-32 h-3" />
              </div>
            ) : (
              <div className="flex gap-5 flex-col items-center w-full h-full">
                <Avatar className="w-20 h-20">
                  <AvatarFallback className="text-4xl w-full ">
                    {user?.first_name !== ""
                      ? user?.first_name?.slice(0, 1)
                      : "A"}
                    {user?.last_name !== ""
                      ? user?.last_name?.slice(0, 1)
                      : "S"}
                  </AvatarFallback>
                </Avatar>
                <p className="font-bold">
                  {user?.first_name?.toUpperCase() +
                    " " +
                    user?.last_name?.toUpperCase()}
                </p>
                <div className="flex justify-between w-full">
                  <div className="flex flex-col gap-1 font-medium">
                    {billing?.email && (
                      <p className="text-sm">Email</p>
                    )}
                    {billing?.phone && (
                      <p className="text-sm">Phone</p>
                    )}
                    {billing?.country && (
                      <p className="text-sm">Country</p>
                    )}
                    {billing?.city && (
                      <p className="text-sm">City</p>
                    )}
                    {billing?.state && (
                      <p className="text-sm">Town</p>
                    )}
                    {billing?.address_1 && (
                      <p className="text-sm">Building</p>
                    )}
                  </div>
                  <div className="flex flex-col gap-1 text-neutral-600 dark:text-neutral-400">
                    {billing?.email && (
                      <p className="text-sm">
                        {billing?.email}
                      </p>
                    )}
                    {billing?.phone && (
                      <p className="text-sm">
                        {billing?.phone}
                      </p>
                    )}
                    {billing?.country && (
                      <p className="text-sm">
                        {billing?.country}
                      </p>
                    )}
                    {billing?.city && (
                      <p className="text-sm">
                        {billing?.city}
                      </p>
                    )}
                    {billing?.state && (
                      <p className="text-sm">
                        {billing?.state}
                      </p>
                    )}
                    {billing?.address_1 && (
                      <p className="text-sm">
                        {billing?.address_1}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="grow flex flex-col gap-5">
              {user && (
                <EditProfile
                  userInfo={{
                    id: user?.id || 0,
                    email: user?.email || "",
                    first_name: user?.first_name || "",
                    last_name: user?.last_name || "",
                  }}
                />
              )}
              {user && (
                <BillingInfo
                  userInfo={billing}
                  userEmail={user?.email || ""}
                />
              )}
              <ChangePassword id={String(user?.id)} />
            </div>
            <Button
              onClick={SignOut}
              className="rounded-none"
            >
              SIGN OUT
            </Button>
          </div>

          <UserOrders userId={user?.id || 0} />
        </div>
      )}
    </div>
  );
}
