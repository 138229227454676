import React from "react";
import { WooCommerce } from "../../../src/lib/api";
import { useQuery, useQueryClient } from "react-query";
import {
  Card,
  CardContent,
  CardTitle,
} from "../../../src/components/ui/card";
import { Separator } from "../../../src/components/ui/separator";
import { Link, useParams } from "react-router-dom";
import MyLoader from "../../../src/components/MyLoader";
import { useCookies } from "react-cookie";

export default function Order() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "cartKey",
    "userToken",
  ]);
  const reactQuery = useQueryClient();
  const orderId = useParams().orderId;
  const { data: order, isLoading } = useQuery(
    ["order", orderId],
    async () => {
      removeCookie("cartKey");
      const res = WooCommerce.get(
        `orders/${String(orderId)}`
      )
        .then((response: any) => {
          reactQuery.refetchQueries(["cartItems"]);
          return response.data;
        })

        .catch((error: any) => {
          console.log("error getting product", error);
        })
        .finally(() => {
          reactQuery.refetchQueries(["cartItems"]);
        });
      return res;
    }
  );
  return isLoading ? (
    <MyLoader />
  ) : (
    <div className="mt-16 sm:mt-36 sm:p-10 p-5 font-montserrat">
      <h2 className="text-2xl font-medium mb-10">
        Thank you, your order has been received
      </h2>

      <div className="flex sm:flex-row flex-col sm:flex-nowrap flex-wrap justify-between items-start gap-5">
        <div className="flex flex-col gap-2 justify-center items-start sm:border-r sm:border-l sm:pl-5 border-neutral-600 pr-5 sm:w-full w-fit">
          <h2 className="font-bold sm:text-md text-sm">
            Order Number
          </h2>
          <h3 className="font-mediun sm:text-md text-sm">
            #{order?.id}
          </h3>
        </div>
        <div className="sm:w-full flex flex-col gap-2 justify-center items-start sm:border-r border-neutral-600 pr-5 sm:text-md text-sm">
          <h2 className="font-bold sm:text-md text-sm">
            Date
          </h2>
          <h3 className="font-mediun sm:text-md text-sm">
            {order?.date_created.slice(0, 10)}
          </h3>
        </div>

        <div className="flex flex-col gap-2 justify-center items-start sm:w-full sm:text-md text-sm mb-5">
          <h2 className="font-bold ">Paid</h2>
          <h3 className="font-mediun">
            {order?.payment_method.charAt(0).toUpperCase() +
              order?.payment_method.slice(1)}
          </h3>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col justify-between items-stretch gap-10 h-fit">
        <Card className="w-full pt-5 text-sm rounded-none border-none shadow-none">
          <CardTitle>Customer Details</CardTitle>
          <Separator className="mt-2 mb-2" />
          <CardContent className="flex gap-2 flex-col p-0">
            <div className="flex justify-between items-center w-full">
              <span>Name</span>
              <span>
                {order?.billing?.first_name +
                  " " +
                  order?.billing?.last_name}
              </span>
            </div>
            <div className="flex justify-between items-center w-full">
              <span>Address</span>
              <span>{order?.billing?.address_1}</span>
            </div>
            <div className="flex justify-between items-center w-full">
              <span>City</span>
              <span>{order?.billing?.city}</span>
            </div>
            <div className="flex justify-between items-center w-full">
              <span>Country</span>
              <span>{order?.billing?.country}</span>
            </div>
            <div className="flex justify-between items-center w-full">
              <span> Email</span>
              <span>{order?.billing?.email}</span>
            </div>
            <div className="flex justify-between items-center w-full">
              <span>Phone</span>
              <span>{order?.billing?.phone}</span>
            </div>
          </CardContent>
        </Card>
        <Card className="w-full p-5 text-sm rounded-none">
          <CardTitle>Order Details</CardTitle>
          <Separator className="mt-2 mb-2" />
          <CardContent className="flex gap-2 flex-col p-0">
            {order?.line_items?.map((item: any) => (
              <div
                key={item.id}
                className="flex justify-between items-center gap-2"
              >
                <span>
                  {item.name + " x" + item.quantity}
                </span>
                <span>{item.price}$</span>
              </div>
            ))}
            <Separator />

            <ul className="grid gap-3">
              <li className="flex items-center justify-between">
                <span className="text-muted-foreground">
                  Subtotal
                </span>
                <span>
                  {order?.line_items?.reduce(
                    (acc: any, item: any) =>
                      acc + Number(item.subtotal),
                    0
                  )}
                  $
                </span>
              </li>
              <li className="flex items-center justify-between">
                <span className="text-muted-foreground">
                  Shipping
                </span>
                <span>{order?.shipping_total}$</span>
              </li>
              <li className="flex items-center justify-between">
                <span className="text-muted-foreground">
                  Tax
                </span>
                <span>{order?.total_tax}$</span>
              </li>
              <li className="flex items-center justify-between">
                <span className="text-muted-foreground">
                  Promo Code Discount
                </span>
                <span>{order?.discount_total}$</span>
              </li>
              <li className="flex items-center justify-between font-medium">
                <span className="text-muted-foreground">
                  Total
                </span>
                <span>{order?.total}$</span>
              </li>
            </ul>
          </CardContent>
        </Card>
      </div>
      <div className=" flex justify-center items-center sm:justify-end">
        <Link
          to={cookies.userToken ? "/profile" : "/"}
          className="bg-neutral-900 p-2 text-neutral-200 pl-4 pr-4 mt-10"
        >
          {cookies.userToken ? "VIEW ORDERS" : `HOME`}
        </Link>
      </div>
    </div>
  );
}
