import React from "react";

export default function InstaHome() {
  return (
    <div className="w-full">
      <div className="dark:bg-neutral-100 bg-neutral-900  w-full dark:text-neutral-900  text-neutral-100 flex justify-center items-center p-2 font-montserrat gap-2">
        <img
          src={"/instagram-fill-white.svg"}
          alt="instagram"
          className="w-5 h-5 dark:invert"
        />
        <a
          href={"https://www.instagram.com/amadeussabra.lb"}
          className="sm:text-md text-sm"
          target="_blank"
          rel="noreferrer"
        >
          FOLLOW US ON INSTAGRAM
        </a>
      </div>
    </div>
  );
}
