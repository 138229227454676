import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "./ui/sheet";

import { ChevronRight, MenuIcon } from "lucide-react";
import { wooCategory } from "../lib/interfaces";
import { Link } from "react-router-dom";
import { WooCommerce } from "../lib/api";
import React from "react";
import { useQuery } from "react-query";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { ModeToggle } from "./themeToggle";
import SocialIcons from "./socialIcons";
export function CategoriesDrawer({
  scrolled,
}: {
  scrolled: boolean;
}) {
  const hairCareCategories = [
    { id: 1, name: "Shampoo", categoryId: 375 },
    { id: 2, name: "Conditioner", categoryId: 373 },
    { id: 3, name: "Mask", categoryId: 371 },
    { id: 4, name: "Oils And Serum", categoryId: 366 },
    { id: 5, name: "Treatment", categoryId: 410 },
  ];
  const [selectedCategoryId, setSelectedCategoryId] =
    React.useState(null);

  const [categoryOpen, setCategoryOpen] =
    React.useState(false);

  const showSubCategory = (categoryId: any) => {
    setSelectedCategoryId((prevCategoryId) =>
      prevCategoryId === categoryId ? null : categoryId
    );
    setCategoryOpen(selectedCategoryId ? false : true);
  };
  const { data: tags } = useQuery(["tags"], async () => {
    const res = WooCommerce.get(
      "products/tags/?per_page=100"
    )
      .then((response: any) => {
        return response.data;
      })
      .catch((error: any) => {
        console.log(error.response.data);
      });
    return res;
  });
  const { data: categories } = useQuery(
    ["wooCategories"],
    async () => {
      const res: wooCategory[] = WooCommerce.get(
        "products/categories/?per_page=100"
      )
        .then((response: any) => {
          return response.data;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });
      return res;
    }
  );
  return (
    <Sheet>
      <SheetTrigger name="burger menu">
        <MenuIcon
          className={
            scrolled
              ? "h-5 w-5"
              : "text-neutral-200 h-5 w-5"
          }
        />
      </SheetTrigger>

      <SheetContent
        side={"left"}
        className="outline-none flex flex-col p-3"
      >
        <SheetHeader>
          <SheetTitle className="flex justify-center mt-2">
            <img
              src="/logo-aaa.png"
              alt=""
              className={"dark:invert w-12"}
            />
          </SheetTitle>
        </SheetHeader>
        <Tabs
          defaultValue="hair-care"
          className="font-montserrat outline-none focus:outline-none"
        >
          <TabsList className="w-full rounded-none outline-none focus:outline-none">
            <TabsTrigger
              value="hair-care"
              className="w-full pt-2 rounded-none"
            >
              HAIR CARE
            </TabsTrigger>
            <TabsTrigger
              value="brands"
              className="w-full pt-2 rounded-none"
            >
              BRANDS
            </TabsTrigger>
          </TabsList>

          <TabsContent
            value="hair-care"
            className="outline-none focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-none focus-visible:ring-offset-0 h-full"
          >
            <div className="flex flex-col gap-5 grow pt-5 outline-none focus:outline-none h-full">
              {hairCareCategories.map((category) => (
                <div
                  key={category.id}
                  onClick={(e) => {
                    e.preventDefault();
                    showSubCategory(category.id);
                  }}
                  className={`w-full ${
                    selectedCategoryId === category.id
                      ? "border-none h-full"
                      : "border-b"
                  } flex flex-col cursor-pointer`}
                >
                  <div className="flex justify-between mb-2">
                    <Link
                      to={`/shop/category/${
                        category.categoryId
                      }/${encodeURIComponent(
                        category.name
                      )}`}
                    >
                      <SheetClose
                        className={`flex justify-between hover:underline cursor-pointer items-center ont-montserrat w-fit`}
                      >
                        {category.name.toLocaleUpperCase()}
                      </SheetClose>
                    </Link>
                    <ChevronRight
                      className={`transform transition-transform ${
                        selectedCategoryId === category.id
                          ? "rotate-90"
                          : "rotate-0"
                      }`}
                    />
                  </div>
                  <div
                    className={`flex flex-col overflow-scroll no-scrollbar ${
                      selectedCategoryId === category.id &&
                      "h-48"
                    }`}
                  >
                    {selectedCategoryId === category.id &&
                      tags
                        ?.filter((tag: any) =>
                          tag.name.includes(category.name)
                        )
                        .map((sub: any) => (
                          <div
                            key={sub.id}
                            className={
                              "mt-2  ml-2 font-montserrat hover:underline"
                            }
                          >
                            <Link
                              to={`/shop/hairType/${sub.id}/${sub.name}`}
                              className="hover:underline cursor-pointer font-montserrat text-[12px]"
                            >
                              <SheetClose className="flex justify-between items-center w-full border-b border-b-neutral-200 dark:border-b-neutral-800 pb-2">
                                {sub.name}
                              </SheetClose>
                            </Link>
                          </div>
                        ))}
                  </div>
                </div>
              ))}
            </div>
          </TabsContent>
          <TabsContent
            value="brands"
            className="outline-none focus:outline-none h-full"
          >
            <div className="flex flex-col gap-5 pt-5 grow outline-none focus:outline-none">
              {categories
                ?.filter((cat) => cat.parent === 367)
                .map((category) => (
                  <div
                    key={category.id}
                    onClick={(e) => {
                      e.preventDefault();
                      showSubCategory(category.id);
                    }}
                    className={`w-full ${
                      selectedCategoryId === category.id
                        ? "border-none h-400px]"
                        : "border-b"
                    } flex flex-col cursor-pointer`}
                  >
                    <div className={`flex justify-between`}>
                      <Link
                        to={`/shop/category/${
                          category.id
                        }/${encodeURIComponent(
                          category.name
                        )}`}
                      >
                        <SheetClose
                          className={`flex justify-between items-center border-b-neutral-200 dark:border-b-neutral-800 pb-2`}
                        >
                          <p className="hover:underline cursor-pointer font-montserrat w-fit">
                            {category.name.toLocaleUpperCase()}
                          </p>
                        </SheetClose>
                      </Link>
                      <ChevronRight
                        className={`transform transition-transform ${
                          selectedCategoryId === category.id
                            ? "rotate-90"
                            : "rotate-0"
                        }`}
                      />
                    </div>
                    <div
                      className={`flex flex-col gap-2 overflow-scroll no-scrollbar ${
                        selectedCategoryId ===
                          category.id && "h-[400px]"
                      }`}
                    >
                      {selectedCategoryId === category.id &&
                        categories
                          ?.filter(
                            (sub) =>
                              sub.parent === category.id
                          )
                          .map((sub) => (
                            <div
                              key={sub.id}
                              className={`mt-2 ml-2 font-montserrat hover:underline `}
                            >
                              <Link
                                to={`/shop/category/${sub.id}/${sub.name}`}
                                className="hover:underline cursor-pointer font-montserrat text-[12px]"
                              >
                                <SheetClose className="flex justify-between items-center w-full border-b border-b-neutral-200 dark:border-b-neutral-800">
                                  {sub.name}
                                </SheetClose>
                              </Link>
                            </div>
                          ))}
                    </div>
                  </div>
                ))}
            </div>
          </TabsContent>
        </Tabs>
        <div className="flex justify-between items-end grow h-fit bg-white dark:bg-neutral-900">
          <div className=" sm:hidden block w-full">
            <ModeToggle scrolled={scrolled} drawer />
          </div>
          <SocialIcons drawer />
        </div>
      </SheetContent>
    </Sheet>
  );
}
