import MyLoader from "../../../../components/MyLoader";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import React from "react";
import { useQuery } from "react-query";
import { course } from "../../../../lib/interfaces";
import { MonitorPlay } from "lucide-react";

export default function CoursesCounter() {
  const { data: courses, isLoading } = useQuery(
    ["api/Course/Get_Courses"],
    async () => {
      const res = await fetch(
        "http://canadian-eye-center.com/api/Course/Get_Courses",
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error: ${res.status}`);
      }

      const receivedCourses: course[] = await res.json();
      return receivedCourses;
    }
  );
  return (
    <Card className="w-48 flex flex-col justify-center items-center">
      <CardHeader className="flex justify-center items-end gap-2 flex-row">
        <MonitorPlay />
        <CardTitle>Courses</CardTitle>
      </CardHeader>
      <CardContent className="text-[36px] font-bold flex justify-center items-center">
        {isLoading ? <MyLoader /> : courses?.length}
      </CardContent>
    </Card>
  );
}
