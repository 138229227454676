import React from "react";

export default function SocialIcons({
  drawer,
}: {
  drawer?: boolean;
}) {
  const phone = "+9613976780";
  const message =
    "Greetings! I would like to make an appointment";
  return (
    <div
      className={
        drawer
          ? "flex gap-3 justify-end items-start w-full"
          : "flex gap-3 justify-center items-start"
      }
    >
      <a
        href="https://www.instagram.com/amadeussabra.lb"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/instagram-fill.svg"
          alt="fb"
          width={22}
          className="dark:invert  duration-300 cursor-pointer -mt-[1px] "
        />{" "}
      </a>
      <a
        href="https://www.facebook.com/amadeussabra"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/fb-icon-2.svg"
          alt="fb"
          width={18}
          className="dark:invert  duration-300 cursor-pointer"
        />{" "}
      </a>
      <a
        href={`https://wa.me/${phone}?text=${message}`}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/wp-icon-2.svg"
          alt="fb"
          width={18}
          className=" dark:invert  duration-300 cursor-pointer"
        />
      </a>
      <a
        href="mailto:hr@amadeussabra.com"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="/email-icon-2.svg"
          alt="fb"
          width={18}
          className="dark:invert  duration-300 cursor-pointer ml-1"
        />
      </a>
    </div>
  );
}
