import React, { useRef, useState, useEffect } from "react";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from "../../components/ui/carousel";
import {
  CircleDot,
  Dot,
  Pause,
  Play,
  Volume2,
  VolumeX,
} from "lucide-react";

interface CarouselItemType {
  id: number;
  src: string;
  poster: string;
  slogan: string;
}

const carouselArray: CarouselItemType[] = [
  {
    id: 0,
    src: "/amadeus-videos/vid-1.mp4",
    poster: "/carousel-3.png",
    slogan: "GLOW CONFIDENTLY",
  },
  {
    id: 1,
    src: "/amadeus-videos/vid-2.mp4",
    poster: "/carousel-5.png",
    slogan: "EMBRACE LUXURY",
  },
  {
    id: 2,
    src: "/amadeus-videos/vid-3.mp4",
    poster: "/carousel-4.png",
    slogan: "DEFINE PERFECTION",
  },
];

export function HomeCarousel() {
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [muteVideo, setMuteVideo] = useState(true);
  const [hideControls, setHideControls] = useState(false);
  const [playingStates, setPlayingStates] = useState<
    boolean[]
  >([]);

  // Use the useCarousel hook to get access to the carousel's API
  const [api, setApi] = React.useState<CarouselApi>();
  const [current, setCurrent] = React.useState(0);
  useEffect(() => {
    if (!api) {
      return;
    }
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);
  useEffect(() => {
    setPlayingStates(
      new Array(carouselArray.length).fill(false)
    );
    const firstVideo = videoRefs.current[0];
    if (firstVideo) {
      firstVideo
        .play()
        .catch((error) =>
          console.error(
            "Error autoplaying first video:",
            error
          )
        );

      setPlayingStates((prevStates) =>
        prevStates.map((_, i) => i === 0)
      );
    }
  }, []);

  const handlePlayPause = (index: number) => {
    const currentVideo = videoRefs.current[index];
    if (currentVideo) {
      if (playingStates[index]) {
        currentVideo.pause();
      } else {
        currentVideo.play();

        // Pause other videos

        videoRefs.current.forEach((video, i) => {
          if (i !== index && video) video.pause();
        });
      }
      // Update the playing states accordingly
      setPlayingStates((prevStates) =>
        prevStates.map((_, i) =>
          i === index ? !prevStates[index] : false
        )
      );
    }
  };
  const changeSlide = (i: number) => {
    api?.scrollTo(i);
    handlePlayPause(i);
  };

  useEffect(() => {
    const hideButtons = () => {
      setHideControls(true);
    };
    const showButtons = () => {
      setHideControls(true);
    };
    document
      .getElementById("carousel")
      ?.addEventListener("mouseenter", showButtons);
    document
      .getElementById("carousel")
      ?.addEventListener("mouseleave", hideButtons);
    return () => {
      document.removeEventListener(
        "mouseenter",
        showButtons
      );
      document.removeEventListener(
        "mouseleave",
        hideButtons
      );
    };
  }, [hideControls]);

  return (
    <div className="relative" id="carousel">
      <div className="absolute bottom-4 justify-center sm:right-2 sm:w-fit right-[45%] font-medium flex sm:flex-col gap-1 text-neutral-200 text-md z-10">
        {carouselArray.map((videoData) => (
          <button
            key={videoData.id}
            className={`pl-2 flex gap-1 ${
              !hideControls ? "hidden" : ""
            }`}
            onClick={() => changeSlide(videoData.id)}
          >
            {current === videoData.id + 1 ? (
              <CircleDot className="text-neutral-200 w-4" />
            ) : (
              <Dot className="text-neutral-200 w-4" />
            )}
            <span
              className={`sm:block hidden ${
                current === videoData.id + 1
                  ? "text-neutral-200 "
                  : "text-neutral-200"
              }`}
            >
              0{videoData.id + 1}
            </span>
          </button>
        ))}
      </div>
      <Carousel setApi={setApi} opts={{ loop: true }}>
        <CarouselContent>
          {carouselArray.map((videoData, index) => (
            <CarouselItem
              key={videoData.id}
              className="relative p-0 basis-full"
            >
              <div
                className={`absolute w-full h-100 bg-neutral-800 opacity-30 ${
                  !hideControls && " hidden"
                }`}
              ></div>
              <video
                ref={(el) =>
                  (videoRefs.current[index] = el)
                }
                id="hero-video"
                height="100%"
                width="100%"
                playsInline
                muted={muteVideo}
                poster={videoData.poster}
                aria-label={`Video ${index + 1}: ${
                  videoData.slogan
                }`}
                onContextMenu={(e) => e.preventDefault()}
                onEnded={() => api?.scrollTo(index + 1)}
              >
                <source
                  src={videoData.src}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>

              <button
                onClick={() => handlePlayPause(index)}
                aria-label={
                  playingStates[index]
                    ? "Pause video"
                    : "Play video"
                }
                className={`absolute text-neutral-200 left-8 sm:left-6 bottom-[18px] flex z-50 ${
                  !hideControls && "hidden"
                }`}
              >
                {playingStates[index] ? (
                  <Pause className="w-5 h-5 sm:h-6 sm:w-6 cursor-pointer" />
                ) : (
                  <Play className="w-5 h-5 sm:h-6 sm:w-6 cursor-pointer" />
                )}
              </button>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      <button
        onClick={() => setMuteVideo(!muteVideo)}
        aria-label={
          muteVideo ? "Unmute video" : "Mute video"
        }
        className="z-50 absolute bottom-4 right-4 sm:left-12 sm:right-auto cursor-pointer text-neutral-200"
      >
        {muteVideo ? (
          <VolumeX className="w-6 h-6 sm:h-7 sm:w-7" />
        ) : (
          <Volume2 className="w-6 h-6 sm:h-7 sm:w-7" />
        )}
      </button>
    </div>
  );
}
