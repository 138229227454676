import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../src/components/ui/button";
import React from "react";

export default function Services() {
  const country = useParams().country;
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 25);
  }, []);
  return (
    <div className="flex flex-col w-screen h-100 font-montserrat lg:mt-36 mt-16 p-5">
      <h2 className="text-xl sm:text-2xl font-montserrat font-medium lg:mb-0 mb-10">
        AMADEUS SERVICES IN {country?.toUpperCase()}
      </h2>
      <div className="flex justify-evenly items-center gap-5 lg:p-32 lg:flex-row flex-col w-full">
        <div className="flex rounded-none border w-full">
          <img
            src="/amadeus-women.png"
            alt="amadeus-women"
            className="w-40 sm:w-72"
          />

          <div className="flex flex-col h-100 gap-5 justify-center items-center w-full">
            <h3 className="lg:text-xl text-center text-md font-medium w-full">
              AMADEUS WOMEN
            </h3>
            <Button
              className="rounded-none"
              onClick={() => {
                navigate("/services/lebanon/lebanonWomen");
              }}
            >
              VIEW SERIVCES
            </Button>
          </div>
        </div>
        <div className="flex rounded-none w-full border">
          <img
            src="/amadeus-men.png"
            alt="amadeus-men"
            className="sm:w-72 w-40"
          />

          <div className="flex flex-col h-100 gap-5 justify-center items-center w-full">
            <h3 className="lg:text-xl text-center text-md font-medium">
              AMADEUS MEN
            </h3>
            <Button
              className="rounded-none"
              onClick={() => {
                navigate("/services/lebanon/lebanonMen");
              }}
            >
              VIEW SERIVCES
            </Button>
          </div>
        </div>
      </div>
      <div id="leb"></div>
    </div>
  );
}
