import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";
import WishlistProduct from "./wishlistProduct";
import useWishlist from "../../hooks/useWishlist";
import React from "react";
export default function WishlistPage() {
  const nav = useNavigate();
  const [wishlistArray, setWishlistArray] = React.useState(
    []
  );
  const { data: wishlist } = useWishlist();
  React.useEffect(() => {
    const wishlistArray = JSON.parse(
      window.localStorage.getItem("wishlistArray") as string
    );
    setWishlistArray(wishlistArray);
  }, []);
  return (
    <div className="flex flex-col w-screen h-100 font-montserrat sm:mt-36 mt-16 p-5">
      <h1 className="sm:text-2xl text-xl mb-5 font-montserrat font-medium">
        WISHLIST
      </h1>
      {!wishlist || !wishlistArray ? (
        <div className="flex flex-col justify-center items-center gap-5 text-center h-96">
          <h1 className=" text-xl font-montserrat">
            Your wishlist is empty!
          </h1>

          <Button
            className=" font-montserrat rounded-none"
            onClick={() => nav("/shop")}
          >
            SHOP NOW
          </Button>
        </div>
      ) : (
        <div className="flex justify-start items-center flex-wrap sm:gap-5 gap-1 w-full">
          {wishlist ? (
            wishlist?.map((wish: any) => (
              <div
                key={wish.prod_id}
                className="sm:w-fit w-[49%]"
              >
                <WishlistProduct
                  productId={Number(wish.prod_id)}
                  wishlist={wishlist}
                />
              </div>
            ))
          ) : wishlistArray.length < 1 ? (
            <div className="flex flex-col justify-center items-center gap-5 text-center h-96">
              <h1 className=" text-xl font-montserrat">
                Your wishlist is empty!
              </h1>

              <Button
                className=" font-montserrat rounded-none"
                onClick={() => nav("/shop")}
              >
                SHOP NOW
              </Button>
            </div>
          ) : (
            wishlistArray?.map((wish: any) => (
              <div key={wish.prod_id}>
                <WishlistProduct
                  productId={Number(wish.prod_id)}
                  wishlist={wishlistArray}
                />
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}
