import MyLoader from "../../../../components/MyLoader";
import { Input } from "../../../../components/ui/input";
import { Layers, SearchIcon } from "lucide-react";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import React from "react";
import SubcategoryDialog from "./SubcategoryDialog";
import { useQuery } from "react-query";
import {
  subcategory,
  category,
} from "../../../../lib/interfaces";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { searchSubcategoryByTitle } from "../../../../lib/utils";
import DeleteSubcategory from "./DeleteSubcategory";

export default function SubcategoriesCard() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [categoryId, setCategoryId] =
    React.useState<number>(0);
  const { data: categories } = useQuery(
    ["api/Category/Get_Categories"],
    async () => {
      const res = await fetch(
        `http://canadian-eye-center.com/api/Category/Get_Categories`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error: ${res.status}`);
      }
      const receivedCategories: category[] =
        await res.json();
      return receivedCategories;
    }
  );
  const { data: subcategories, isLoading } = useQuery(
    ["api/SubCategory/Get_SubCategories", categoryId],
    async () => {
      const res = await fetch(
        `http://canadian-eye-center.com/api/SubCategory/Get_SubCategories/${categoryId}`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error: ${res.status}`);
      }
      const receivedSubcategories: subcategory[] =
        await res.json();
      return receivedSubcategories;
    }
  );
  return (
    <Card
      className="p-5 shadow-xl 
      sm:min-h-[560px] flex flex-col justify-between gap-5 w-full"
    >
      <CardHeader className="flex items-center border-b justify-between flex-row gap-2 p-0 pb-2">
        <div className="flex items-end justify-center flex-row gap-2 p-2">
          <Layers />
          <CardTitle>Subcategories</CardTitle>
        </div>
      </CardHeader>
      <Select
        onValueChange={(e) => {
          setCategoryId(Number(e));
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select a category" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>Categories</SelectLabel>
            {categories?.map((category: category) => (
              <SelectItem
                key={category.CategoryID}
                value={String(category.CategoryID)}
              >
                {category.CategoryName}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      <div className="flex justify-end items-center gap-2 relative ">
        <SearchIcon className="absolute right-2 auto text-neutral-400" />
        <Input
          className="focus:outline-neutral-900  "
          placeholder="Search Subcategories..."
          type="text"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <CardContent className="flex p-0 flex-col gap-2 overflow-scroll no-scrollbar no-scrollbar sm:max-h-[300px] h-full">
        {isLoading ? (
          <MyLoader />
        ) : (
          subcategories &&
          searchSubcategoryByTitle(
            subcategories,
            searchQuery
          )?.map((subcategory: subcategory) => (
            <Card
              className="flex gap-2 justify-between items-center p-0"
              key={subcategory.SubCategoryID}
            >
              <CardHeader>
                {subcategory.SubCategoryDesc}

                <p className="text-sm text-neutral-400">
                  {subcategory.Notes}
                </p>
              </CardHeader>

              <CardFooter className="flex gap-2 justify-center items-center p-0 pr-6">
                <SubcategoryDialog
                  subcategory={subcategory}
                />
                <DeleteSubcategory
                  subcategoryId={subcategory.SubCategoryID}
                  categoryId={categoryId}
                />
              </CardFooter>
            </Card>
          ))
        )}
      </CardContent>
      <CardFooter className="flex gap-1 justify-end items-center border-t p-0 pt-5">
        <SubcategoryDialog />
      </CardFooter>
    </Card>
  );
}
