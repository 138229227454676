import MyLoader from "../../../../components/MyLoader";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import React from "react";
import { useQuery } from "react-query";
import { category } from "../../../../lib/interfaces";
import { Users } from "lucide-react";
import { useCookies } from "react-cookie";
import { apiCall } from "../../../../../src/lib/api";

export default function UsersCounter() {
  const [cookies] = useCookies(["userToken"]);
  const { data: users, isLoading } = useQuery(
    ["api/Users"],
    async () => {
      const res = await fetch(
        `${apiCall}/wp-json/wp/v2/users/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.userToken}`,
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error: ${res.status}`);
      }
      const receivedCategories: category[] =
        await res.json();
      return receivedCategories;
    }
  );
  return (
    <Card className="w-48 flex flex-col justify-center items-center">
      <CardHeader className="flex justify-center items-end gap-2 flex-row">
        <Users />
        <CardTitle>Users</CardTitle>
      </CardHeader>
      <CardContent className="text-[36px] font-bold flex justify-center items-center">
        {isLoading ? <MyLoader /> : users?.length}
      </CardContent>
    </Card>
  );
}
