import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/ui/button";

export default function ErrorPage() {
  const nav = useNavigate();

  return (
    <div className="flex justify-center items-center flex-col h-screen w-screen gap-2 font-montserrat">
      <img src="/404.png" alt="404" width={600} />
      <h1 className="text-48 font-bold">
        Oops, this page does not exist!
      </h1>
      <Button
        className="rounded-none"
        onClick={() => nav("/")}
      >
        Home
      </Button>
    </div>
  );
}
