import React from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../src/components/ui/table";
import {
  cartDetails,
  cartItem,
} from "../products/productInterface";
import { useCookies } from "react-cookie";
import { apiCall } from "../../../src/lib/api";
import { Input } from "../../../src/components/ui/input";
import { useQueryClient } from "react-query";
import RemoveFromCart from "../../../src/components/removeFromCart";
import { Button } from "../../../src/components/ui/button";
import { useNavigate } from "react-router-dom";

export function CartTable({ cart }: { cart: cartDetails }) {
  const [quantityLoading, setQuantityLoading] =
    React.useState(false);
  const reactQuery = useQueryClient();
  const [cookies] = useCookies(["cartKey", "userToken"]);
  const nav = useNavigate();
  async function updateProductQuantity(
    id: string,
    quantity: string
  ) {
    setQuantityLoading(true);
    const key = await cookies.cartKey;
    const res = await fetch(
      `${apiCall}/wp-json/cocart/v2/cart/item/${id}/${
        cookies.userToken ? "" : `?cart_key=${key}`
      }`,
      {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: !cookies.userToken
            ? ""
            : `Bearer ${cookies.userToken}`,
        },

        body: JSON.stringify({
          quantity: quantity,
        }),
      }
    );
    if (!res.ok) {
      console.log(`HTTP error: ${res.status}`);
    }

    const response = await res.json();
    reactQuery.refetchQueries(["cartItems"]);
    setQuantityLoading(false);
    return response;
  }
  return cart?.items?.length < 1 ? (
    <div className="w-full flex flex-col justify-center items-center gap-5 h-96">
      <p className="font-montserrat text-xl">
        Your cart is empty!
      </p>
      <Button
        onClick={() => nav("/shop")}
        className="font-montserrat rounded-none "
      >
        SHOP NOW
      </Button>
    </div>
  ) : (
    <Table className="w-full font-montserrat">
      <TableCaption>
        A list of your cart items.
      </TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[10px]"></TableHead>
          <TableHead className="w-[300px] pl-10">
            Item
          </TableHead>
          <TableHead className="w-[100px]">Price</TableHead>
          <TableHead className="w-[100px]">
            Quantity
          </TableHead>
          <TableHead className="w-[100px] text-right">
            Subtotal
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {cart?.items?.map((cartItem: cartItem) => (
          <TableRow key={cartItem.id}>
            <TableCell className="w-10">
              <RemoveFromCart
                itemId={cartItem.item_key}
                cart
                name={cartItem.name.toLocaleUpperCase()}
              />
            </TableCell>
            <TableCell className="font-medium">
              <div className="flex justify-center items-center gap-2">
                <img
                  src={cartItem.featured_image}
                  alt={cartItem.name}
                  className="w-16"
                />
                <p className="w-[200px]">
                  {cartItem.name.toLocaleUpperCase()}
                </p>
              </div>
            </TableCell>
            <TableCell>{cartItem.price}$</TableCell>
            <TableCell>
              <Input
                type="number"
                min={1}
                defaultValue={cartItem.quantity.value}
                disabled={quantityLoading}
                onChange={(e) =>
                  updateProductQuantity(
                    cartItem.item_key,
                    e.target.value
                  )
                }
                className="w-16 focus:outline-none"
              />
            </TableCell>
            <TableCell className="text-right">
              {cartItem.totals.subtotal}$
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
