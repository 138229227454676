import MyLoader from "../../../../components/MyLoader";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import React from "react";
import { useQuery } from "react-query";
import { category } from "../../../../lib/interfaces";
import { Layers2 } from "lucide-react";

export default function CategoriesCounter() {
  const { data: categories, isLoading } = useQuery(
    ["api/Category/Get_Categories"],
    async () => {
      const res = await fetch(
        `http://canadian-eye-center.com/api/Category/Get_Categories`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error: ${res.status}`);
      }
      const receivedCategories: category[] =
        await res.json();
      return receivedCategories;
    }
  );
  return (
    <Card className="w-48 flex flex-col justify-center items-center">
      <CardHeader className="flex justify-center items-end gap-2 flex-row">
        <Layers2 />
        <CardTitle>Categories</CardTitle>
      </CardHeader>
      <CardContent className="text-[36px] font-bold flex justify-center items-center">
        {isLoading ? <MyLoader /> : categories?.length}
      </CardContent>
    </Card>
  );
}
