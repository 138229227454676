import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import React from "react";
import { category } from "../../../../lib/interfaces";
import { Layers2, SearchIcon } from "lucide-react";
import CategoryDialog from "./CategoryDialog";
import MyLoader from "../../../../components/MyLoader";
import DeleteCategory from "./DeleteCategory";
import { Badge } from "../../../../components/ui/badge";
import { useQuery } from "react-query";
import { Input } from "../../../../components/ui/input";
import { searchCategoryByTitle } from "../../../../lib/utils";

export default function CategoriesCard() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const { data: categories, isLoading } = useQuery(
    ["api/Category/Get_Categories"],
    async () => {
      const res = await fetch(
        `http://canadian-eye-center.com/api/Category/Get_Categories`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error: ${res.status}`);
      }
      const receivedCategories: category[] =
        await res.json();
      return receivedCategories;
    }
  );
  return (
    <Card
      className="p-5 shadow-xl w-full
      sm:min-h-[560px] flex flex-col justify-between gap-5"
    >
      <CardHeader className="flex items-center border-b justify-between flex-row gap-2 p-0 pb-2">
        <div className="flex items-end justify-center flex-row gap-2 ">
          <Layers2 />
          <CardTitle>Categories</CardTitle>
        </div>
        {isLoading ? (
          <MyLoader />
        ) : (
          <Badge
            variant="outline"
            className="text-2xl flex justify-center items-center m-0"
          >
            {categories?.length}
          </Badge>
        )}
      </CardHeader>
      <p className="flex justify-end items-center gap-2 relative ">
        <SearchIcon className="absolute right-2 auto text-neutral-400" />
        <Input
          className="focus:outline-neutral-900  "
          placeholder="Search Categories..."
          type="text"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </p>
      <CardContent className="flex p-0 flex-col gap-2 overflow-scroll no-scrollbar sm:max-h-[300px] h-full">
        {categories &&
          searchCategoryByTitle(
            categories,
            searchQuery
          )?.map((category) => (
            <Card
              className="w-full flex justify-between items-center"
              key={category.CategoryID}
            >
              <CardHeader>
                {category.CategoryName}
                <p className="text-sm text-neutral-400">
                  {category.Notes}
                </p>
              </CardHeader>

              <CardFooter className="flex justify-center items-center p-0 pr-6">
                <CategoryDialog category={category} />
                <DeleteCategory
                  categoryId={category.CategoryID}
                />
              </CardFooter>
            </Card>
          ))}
      </CardContent>
      <CardFooter className="flex gap-1 justify-end items-center border-t p-0 pt-5">
        <CategoryDialog />
      </CardFooter>
    </Card>
  );
}
