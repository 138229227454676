import { useQuery } from "react-query";
import { apiCall } from "../lib/api";
import { useCookies } from "react-cookie";

export default function useWishlist() {
  const [cookies] = useCookies(["userToken"]);
  const reactQueryData = useQuery(
    ["LoggedInwishlist"],
    async () => {
      if (!cookies.userToken) {
        return (
          JSON.parse(
            localStorage.getItem("wishlistArray") || "null"
          ) || []
        );
      }
      const wishlistId =
        window.localStorage.getItem("wishlist_id");
      const res = await fetch(
        `${apiCall}/wp-json/amadeussabra/v1/get-data?id=${
          wishlistId || ""
        }`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${cookies.userToken}`,
          },
        }
      );
      if (!res.ok) {
        console.log(`HTTP error: ${res.status}`);
        return;
      }

      const response = await res.json();
      return response;
    }
  );
  return reactQueryData;
}
