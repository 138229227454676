import React, { useState } from "react";
import { apiCall } from "../lib/api";
import { Check, Loader } from "lucide-react";
import { toast } from "./ui/use-toast";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

export default function Subscribe() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const saveSubscriptionEmail = async (
    e: React.FormEvent
  ) => {
    e.preventDefault();
    setLoading(true);
    const res = await fetch(
      `${apiCall}/wp-json/email-saver/v1/save-email`,
      {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },

        body: JSON.stringify({
          email: email,
        }),
      }
    );
    if (!res.ok) {
      setLoading(false);
      const response = await res.json();
      console.log(`HTTP error: ${res.status}`);
      toast({
        title: `Subscription Failed, ${response.message}`,
        action: (
          <ExclamationTriangleIcon className="w-5 h-5" />
        ),
        className: "fixed top-0 left-0 sm:relative",
      });
      return;
    }
    const response = await res.json();

    toast({
      title: `Subscription Successful`,
      action: <Check />,
      className: "fixed top-0 left-0 sm:relative",
    });

    setLoading(false);
    return response;
  };
  return (
    <form
      className="flex gap-1 sm:justify-start justify-between w-full"
      onSubmit={saveSubscriptionEmail}
    >
      <Input
        placeholder="Email"
        className="outline-none rounded-none sm:w-64 w-full"
        type="email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <Button
        className="sm:text-md text-sm rounded-none "
        type="submit"
      >
        {loading ? (
          <span className="flex gap-1">
            {" "}
            <Loader className="animate-spin w-5 h-5" />
            SUBSCRIBE
          </span>
        ) : (
          `SUBSCRIBE`
        )}
      </Button>
    </form>
  );
}
