import { SearchIcon } from "lucide-react";

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";
import { Card } from "./ui/card";
import { WooCommerce } from "../lib/api";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "./ui/input";
import MyLoader from "./MyLoader";
import { Skeleton } from "./ui/skeleton";

export default function SearchSheet({
  scrolled,
}: {
  scrolled: boolean;
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const [showInput, setShowInput] = useState(true);
  const [loader, setLoader] = useState(false);
  const [foundProducts, setFoundProducts] =
    useState<any[]>();
  const nav = useNavigate();
  const searchWooProducts = async () => {
    setLoader(true);
    try {
      const res = WooCommerce.get(
        `products?search=${searchQuery}`
      )
        .then((response: any) => {
          setLoader(false);
          setFoundProducts(response.data);

          setShowInput(false);
          return response.data;
        })
        .catch((error: any) => {
          setLoader(false);
          console.log(error.response.data);
        });
      return res;
    } catch (err) {
      setLoader(false);
      console.log("could not search products", err);
    }
  };
  React.useEffect(() => {
    if (searchQuery.length > 3) {
      throttle();
    }
  }, [searchQuery]);

  const loadingProductsTimout =
    React.useRef<NodeJS.Timeout | null>(null);

  const throttle = () => {
    if (loadingProductsTimout.current) return;
    loadingProductsTimout.current = setTimeout(() => {
      loadingProductsTimout.current &&
        clearTimeout(loadingProductsTimout.current);
      loadingProductsTimout.current = null;

      searchWooProducts();
    }, 1000);
  };

  return (
    <Sheet>
      <SheetTrigger asChild>
        <SearchIcon
          className={
            scrolled
              ? "h-5 w-5  text-neutral-900  hover:fill-neutral-900 dark:hover:fill-neutral-200 dark:text-neutral-200"
              : "h-5 w-5 text-neutral-200 hover:fill-neutral-200 "
          }
        />
      </SheetTrigger>
      <SheetContent
        side={"top"}
        className="font-montserrat"
      >
        <SheetHeader>
          <SheetTitle>SEARCH PRODUCTS</SheetTitle>
        </SheetHeader>
        <Input
          className="focus:outline-none bg-transparent text-neutral-900 placeholder:text-neutral-600 dark:text-neutral-200 border-0 border-b dark:border-neutral-800 border-neutral-900 dark:placeholder:text-neutral-200 rounded-none"
          id="desktop-search"
          placeholder="Search..."
          type="text"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />

        {searchQuery !== "" && (
          <div className="bg-white w-full mt-1 rounded-md dark:bg-neutral-900 ">
            <h3 className="mb-2 pb-2 font-medium">
              {`SEARCH RESULTS FOR ${searchQuery}`}
            </h3>

            <div className=" flex flex-wrap gap-x-10 gap-y-5 h-96 overflow-scroll no-scrollbar">
              {loader ? (
                <div className="flex justify-center items-center w-full h-24">
                  <MyLoader />
                </div>
              ) : (
                foundProducts?.map((product, index) => (
                  <SheetClose asChild key={index}>
                    {!showInput ? (
                      <Card
                        className="flex justify-between items-center hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer sm:w-64 w-full rounded-none"
                        onClick={() => {
                          nav(
                            `/product/${product.categories[0]?.id}/${product.categories[0]?.name}/${product.id}/${product.slug}`
                          );
                          setShowInput(false);
                          setSearchQuery("");
                        }}
                      >
                        <div className="flex justify-center items-center gap-2">
                          <div className="w-36 flex justify-center items-center p-2 dark:bg-white">
                            {product && product.images && (
                              <img
                                alt="search-products-img"
                                src={
                                  product?.images[0]?.src ||
                                  "/logo.png"
                                }
                                className="rounded-tl rounded-bl w-full"
                              />
                            )}
                          </div>
                          <div className="flex flex-col gap-1 sm:text-md text-sm w-full font-medium">
                            <h2 className=" w-full">
                              {product.name.toLocaleUpperCase()}
                            </h2>

                            <p className="text-sm font-montserrat font-bold">
                              {product.price}$
                            </p>
                          </div>
                        </div>
                      </Card>
                    ) : (
                      <div className="flex gap-5 justify-center items-center flex-wrap">
                        <div className="flex gap-2 w-full items-center">
                          <Skeleton className="w-16 h-16 rounded-full" />
                          <div className="flex flex-col gap-2">
                            <Skeleton className="w-24 h-4" />
                            <Skeleton className="w-12 h-2" />
                          </div>
                        </div>
                      </div>
                    )}
                  </SheetClose>
                ))
              )}
              {!loader &&
              foundProducts &&
              foundProducts?.length < 1 ? (
                <p>No products found!</p>
              ) : (
                <p></p>
              )}
            </div>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
}
