import { WooCommerce } from "../../../src/lib/api";
import { BookText } from "lucide-react";
import React from "react";
import { useQuery } from "react-query";
import { wooOrder } from "../checkout/orderInterface";
import OrderPreview from "../checkout/orderPreview";
import { Button } from "../../../src/components/ui/button";
import { useNavigate } from "react-router-dom";
import MyLoader from "../../../src/components/MyLoader";

export default function UserOrders({
  userId,
}: {
  userId: number;
}) {
  const nav = useNavigate();
  const { data: orders, isLoading } = useQuery(
    ["userOrders", userId],
    async () => {
      const res = WooCommerce.get(
        `orders?customer=${userId}`
      )
        .then((response: any) => {
          return response.data;
        })

        .catch((error: any) => {
          console.log("error getting product", error);
        });
      return res;
    }
  );
  return (
    <div className="border w-full shadow-xl p-5 h-full">
      <div className="text-xl font-medium flex gap-1 items-center border-b pb-2 mb-2">
        <BookText className="w-4 h-4" />
        <span>Orders</span>
      </div>
      <div className="overflow-y-scroll h-64 gap-2 flex flex-col">
        {isLoading ? (
          <MyLoader />
        ) : (
          orders?.map((order: wooOrder) => (
            <OrderPreview key={order.id} order={order} />
          ))
        )}
        {orders?.length < 1 && (
          <div className="flex flex-col justify-center items-center w-full h-full font-montserrat gap-5">
            <h3>No Orders Yet!</h3>
            <Button
              onClick={() => nav("/shop")}
              className="rounded-none"
            >
              SHOP NOW
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
