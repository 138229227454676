import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import React from "react";
import { course } from "../../../../lib/interfaces";
import { MonitorPlay, SearchIcon } from "lucide-react";
import MyLoader from "../../../../components/MyLoader";
import { Badge } from "../../../../components/ui/badge";
import DeleteCourse from "./DeleteCourse";
import CourseDialog from "./CourseDialog";
import { useQuery } from "react-query";
import { Input } from "../../../../components/ui/input";
import { searchCourseByTitle } from "../../../../lib/utils";
import { Button } from "../../../../components/ui/button";

export default function CoursesCard() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const { data: courses, isLoading } = useQuery(
    ["api/Course/Get_Courses"],
    async () => {
      const res = await fetch(
        "http://canadian-eye-center.com/api/Course/Get_Courses",
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error: ${res.status}`);
      }

      const receivedCourses: course[] = await res.json();
      return receivedCourses;
    }
  );

  return (
    <Card
      className="p-5 shadow-xl 
      sm:min-h-[560px] flex flex-col justify-between gap-2 w-full"
    >
      <CardHeader className="flex items-center border-b justify-between flex-row gap-2 p-0 pb-2">
        <div className="flex items-end justify-center flex-row gap-2 ">
          <MonitorPlay />
          <CardTitle>Courses</CardTitle>
        </div>
        {isLoading ? (
          <MyLoader />
        ) : (
          <Badge
            variant="outline"
            className="text-2xl flex justify-center items-center m-0"
          >
            {courses?.length}
          </Badge>
        )}
      </CardHeader>
      <p className="flex justify-end items-center gap-2 relative">
        <SearchIcon className="absolute right-2 auto text-neutral-400" />
        <Input
          className="focus:outline-neutral-900  "
          placeholder="Search Courses..."
          type="text"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </p>
      <CardContent className="flex p-0 flex-col gap-2 overflow-scroll no-scrollbar no-scrollbar justify-start h-full sm:max-h-[300px] w-full">
        {courses &&
          searchCourseByTitle(courses, searchQuery)?.map(
            (course: course) => (
              <Card
                key={course.COURSE_ID}
                className="p-2 flex justify-between items-center w-full"
              >
                <div className="flex justify-center items-center gap-2">
                  <img
                    src="/course-image.jpg"
                    width={60}
                    className="rounded"
                    alt="courseImage"
                  />
                  <div className="flex flex-col gap-1">
                    <h2>{course.COURSE_TITLE}</h2>
                    <p className="text-sm text-neutral-600 truncate w-[260px]">
                      {course.CATEGORY_NAME}
                    </p>
                    <p className="text-sm font-bold">
                      {course.PRICE}$
                    </p>
                  </div>
                </div>
                <div className="flex gap-1 justify-center items-center">
                  <Badge
                    variant="secondary"
                    className="w-fit"
                  >
                    {course.DURATION > 60 &&
                    course.DURATION % 60 !== 0
                      ? Math.floor(course.DURATION / 60) +
                        `hr ${course.DURATION % 60}min`
                      : course.DURATION > 60
                      ? `${course.DURATION / 60}hr 00min`
                      : `${course.DURATION}min`}
                  </Badge>
                  <CourseDialog course={course} />
                  <DeleteCourse
                    courseId={course.COURSE_ID}
                  />
                </div>
              </Card>
            )
          )}
      </CardContent>

      <CardFooter className="flex gap-1 justify-between items-center border-t p-0 pt-5 ">
        <Button variant="link">View All Courses</Button>
        <CourseDialog />
      </CardFooter>
    </Card>
  );
}
