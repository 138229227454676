export const apiCall = "https://amadeussabra.com/amadeus";

const CoCartAPI =
  require("@cocart/cocart-rest-api").default;

export const CoCart = new CoCartAPI({
  url: `${apiCall}/`, // Your store URL
  consumerKey:
    "ck_1e90ec6c2e315a6cabcd8d4cb5f8b889c102a7b3", // Your consumer key
  consumerSecret:
    "cs_f67bee9761571931e57620401e17c861066367b0",
  version: "cocart/v2",
});
const WooCommerceRestApi =
  require("@woocommerce/woocommerce-rest-api").default;
// import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api"; // Supports ESM
const ckLocal =
  "ck_6ae8ec2b1e0d50085d6f9bd876b3f734e4059710";
const csLocal =
  "cs_672cf0904eb241a56f8a0117e7860b43848c7515";

const ckAm = "ck_1e90ec6c2e315a6cabcd8d4cb5f8b889c102a7b3";
const csAm = "cs_f67bee9761571931e57620401e17c861066367b0";
export const WooCommerce = new WooCommerceRestApi({
  url: `${apiCall}/`, // Your store URL
  consumerKey: ckAm, // Your consumer key
  consumerSecret: csAm, // Your consumer secret
  version: "wc/v3",
  axiosConfig: {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  }, // WooCommerce WP REST API version
});
