import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "./ui/button";
import { ChevronUp } from "lucide-react";
import React from "react";

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();
  const [hideButton, setHideButton] = React.useState(true);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 1000) {
        setHideButton(false);
      } else {
        setHideButton(true);
      }
    });
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Button
      variant={"secondary"}
      className={
        hideButton
          ? "hidden"
          : "sm:bottom-5 bottom-12 right-5 z-50 fixed rounded-none w-12"
      }
      onClick={() => window.scrollTo(0, 0)}
    >
      <ChevronUp className="w-10" />
    </Button>
  );
};

export default ScrollToTop;
