import React from "react";
import CategoriesCard from "./components/Categories/CategoriesCard";
import CoursesCard from "./components/Courses/CoursesCard";
import SubcategoriesCard from "./components/Subcategories/SubcategoriesCard";
import CategoriesCounter from "./components/Counters/CategoriesCounter";
import CoursesCounter from "./components/Counters/CoursesCounter";
import UsersCounter from "./components/Counters/UsersCounter";

export default function AdminDashboard() {
  return (
    <div className="p-32 flex-col flex gap-5 sm:flex-row justify-between flex-wrap">
      <div className="flex gap-5 justify-around items-center w-full">
        <CategoriesCounter />
        <CoursesCounter />
        <UsersCounter />
      </div>
      <div className="flex gap-5 justify-around flex-col sm:flex-row w-full">
        <CategoriesCard />
        <SubcategoriesCard />
      </div>
      <CoursesCard />
    </div>
  );
}
