import TheArtist from "./theArtist";
import InstaHome from "./instaHome";
import HomeCollections from "./homeCollections";
import HairConcern from "./HairConcern";
import HomeBanner from "./homeBanner";
import HomeProducts from "../products/homeProducts";
import { HomeCarousel } from "./homeCarousel";

export default function UserHome() {
  return (
    <div className="w-full flex flex-col lg:gap-32 gap-10">
      <HomeCarousel />
      <HomeCollections
        collectionId={368}
        title="Kerastase"
      />
      <HomeCollections
        collectionId={437}
        title="Schwarzkopf"
      />
      <HomeBanner />
      <HairConcern />
      <TheArtist />
      <HomeProducts title="RECOMMENDED BY AMADEUS" />
      <InstaHome />
    </div>
  );
}
