import { useQueryClient } from "react-query";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import { Layers, TrashIcon } from "lucide-react";
import React from "react";

export default function DeleteCategory({
  categoryId,
}: {
  categoryId: number;
}) {
  const reactQuery = useQueryClient();
  const deleteCategory = async (categoryId: number) => {
    try {
      const response = await fetch(
        `http://canadian-eye-center.com/api/Category/Delete_Category/${categoryId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log(data);
      reactQuery.refetchQueries([
        "api/Category/Get_Categories",
      ]);
    } catch (error) {
      console.error("Error", error);
    }
  };
  return (
    <Dialog>
      <DialogTrigger asChild>
        <TrashIcon
          className="w-4 h-4 cursor-pointer hover:fill-red-500"
          color="red"
        />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] flex flex-col justify-start">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <Layers className="w-4 h-4" />
            Delete Category
          </DialogTitle>
          <DialogDescription>
            Are you sure you want to delete this category?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              variant="destructive"
              onClick={() => deleteCategory(categoryId)}
            >
              Delete Category
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
