import { Input } from "../../components/ui/input";
import React from "react";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Label } from "../../components/ui/label";
import { CheckIcon, ReceiptText } from "lucide-react";
import { useCookies } from "react-cookie";
import { useQueryClient } from "react-query";
import { toast } from "../../components/ui/use-toast";
import { WooCommerce } from "../../../src/lib/api";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { listOfCountries } from "../../../src/lib/interfaces";

export default function BillingInfo({
  userInfo,
  userEmail,
}: {
  userEmail: string;
  userInfo: any;
}) {
  const [cookies] = useCookies(["userToken", "userId"]);
  const [updatedFirstname, setUpdatedFirstname] =
    React.useState<string>(userInfo?.first_name || "");
  const [updatedLastname, setUpdatedLastname] =
    React.useState<string>(userInfo?.last_name || "");
  const [updatedPhone, setUpdatedPhone] =
    React.useState<string>(userInfo?.phone || "");
  const [updatedEmail, setUpdatedEmail] =
    React.useState<string>(
      userInfo?.email || userEmail || ""
    );
  const [updatedCountry, setUpdatedCountry] =
    React.useState<string>(userInfo?.country || "");
  const [updatedCity, setUpdatedCity] =
    React.useState<string>(userInfo?.city || "");
  const [updatedState, setUpdatedState] =
    React.useState<string>(userInfo?.state || "");
  const [updatedBuilding, setUpdatedBuilding] =
    React.useState<string>(userInfo?.building || "");
  const reactQuery = useQueryClient();
  const BillingInfo = {
    id: userInfo?.id,
    billing: {
      first_name: updatedFirstname,
      last_name: updatedLastname,
      address_1: updatedBuilding,
      city: updatedCity,
      email: updatedEmail,
      state: updatedState,
      country: updatedCountry,
      phone: updatedPhone,
    },
  };
  const updateBilling = async () => {
    try {
      WooCommerce.put(
        `customers/${cookies.userId}`,
        BillingInfo
      )
        .then((response: any) => {
          toast({
            title: "Edit Billing Info",
            description:
              "Billing Information changed successfully!",
            action: <CheckIcon />,
            className: "fixed top-0 left-0 sm:relative",
          });
          reactQuery.refetchQueries(["users/billing"]);
          return response.data;
        })
        .catch((error: any) => {
          console.log(error.response.data);
        });
    } catch (error: any) {
      console.log(error.response.data);
    }
  };
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="flex justify-start w-full border gap-2 rounded-none"
        >
          <ReceiptText className="w-4 h-4" />
          <p>EDIT BILLING INFORMATION</p>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            Edit Billing Information
          </DialogTitle>
          <DialogDescription>
            Make changes to your billing information here.
            Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4 w-100">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label
              htmlFor="firstName"
              className="text-right"
            >
              First Name
            </Label>
            <Input
              id="firstName"
              className="col-span-3 focus:outline-none"
              value={updatedFirstname}
              onChange={(e) =>
                setUpdatedFirstname(e.target.value)
              }
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label
              htmlFor="lastName"
              className="text-right"
            >
              Last Name
            </Label>
            <Input
              id="lastName"
              className="col-span-3 focus:outline-none"
              value={updatedLastname}
              onChange={(e) =>
                setUpdatedLastname(e.target.value)
              }
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="email" className="text-right">
              Email
            </Label>
            <Input
              id="email"
              className="col-span-3 focus:outline-none"
              type="text"
              value={updatedEmail}
              onChange={(e) =>
                setUpdatedEmail(e.target.value)
              }
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="phone" className="text-right">
              Phone
            </Label>
            <Input
              id="phone"
              className="col-span-3 focus:outline-none"
              type="text"
              value={updatedPhone}
              onChange={(e) =>
                setUpdatedPhone(e.target.value)
              }
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4 w-100">
            <Label htmlFor="country" className="text-right">
              Country
            </Label>
            <Select
              onValueChange={(e) => setUpdatedCountry(e)}
            >
              <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select a country" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Countries</SelectLabel>
                  {listOfCountries.map((c) => (
                    <SelectItem
                      key={c.value}
                      value={c.label}
                    >
                      {c.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="city" className="text-right">
              City
            </Label>
            <Input
              id="city"
              className="col-span-3 focus:outline-none"
              type="text"
              value={updatedCity}
              onChange={(e) =>
                setUpdatedCity(e.target.value)
              }
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="town" className="text-right">
              Town
            </Label>
            <Input
              id="town"
              className="col-span-3 focus:outline-none"
              type="text"
              value={updatedState}
              onChange={(e) =>
                setUpdatedState(e.target.value)
              }
            />
          </div>

          <div className="grid grid-cols-4 items-center gap-4">
            <Label
              htmlFor="Building"
              className="text-right"
            >
              Building
            </Label>
            <Input
              id="Building"
              className="col-span-3 focus:outline-none"
              type="text"
              value={updatedBuilding}
              onChange={(e) =>
                setUpdatedBuilding(e.target.value)
              }
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              className="rounded-none"
              type="submit"
              onClick={() => updateBilling()}
            >
              SAVE CHANGES
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
