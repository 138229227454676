import { MagicWandIcon } from "@radix-ui/react-icons";

export default function TheArtist() {
  const phone = "+9613976780";
  const message =
    "Greetings! I would like to make an appointment with Amadeus";
  return (
    <div className="w-screen flex flex-row justify-between sm:items-center items-end bg-neutral-200  dark:text-neutral-900">
      <div className="flex flex-col justify-between items-center sm:justify-center text-center gap-2 sm:gap-5 font-montserrat sm:p-0 p-5 w-full">
        <img
          src="logo-aaa.png"
          alt="amadeus-logo"
          className="sm:w-24 w-10 "
        />
        <h5 className="sm:text-2xl text-xl font-montserrat font-medium pt-2">
          THE ARTIST
        </h5>
        <p className="font-light sm:text-md text-sm sm:block hidden">
          We are waiting to create magic with you
        </p>
        <a
          className="rounded-none gap-2 flex p-2 bg-neutral-900 items-start text-neutral-200 dark:hover:bg-neutral-800 w-40 flex justify-center"
          // href={"https://wa.me/9613092036"}
          href={`https://wa.me/${phone}?text=${message}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/wp-icon-2.svg"
            className="w-4 invert"
            alt="whatsapp"
          />

          <span className="sm:text-sm text-[12px]">
            LET'S DO MAGIC
          </span>
        </a>
      </div>
      <div className=" flex justify-center items-end h-full w-full">
        <img
          src="artist.png"
          alt="artist-amadeus"
          className="w-full max-w-96"
        />
      </div>
    </div>
  );
}
