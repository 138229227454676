import * as React from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { useNavigate } from "react-router-dom";
import { Badge } from "../../components/ui/badge";
import { MapPin } from "lucide-react";

export function ServicesLocation({
  switchLogo,
  location,
}: {
  switchLogo: boolean;
  location: string;
}) {
  const nav = useNavigate();
  return (
    <Dialog>
      <DialogTrigger asChild>
        <MapPin
          className={`cursor-pointer  h-4 w-4 ${
            !switchLogo && location === "/"
              ? "text-neutral-200 "
              : "text-neutral-800 dark:text-neutral-200"
          }`}
        />
      </DialogTrigger>
      <DialogContent className="max-w-[300px] rounded-none">
        <DialogHeader>
          <DialogTitle className="flex gap-2 justify-start items-end">
            <img
              src="/logo-aaa.png"
              alt="services-icon"
              className="w-6 dark:invert"
            />
            <span className="-mb-[2px]">
              AMADEUS SERVICES
            </span>
          </DialogTitle>
        </DialogHeader>
        <div className="flex gap-2 justify-center items-center mt-3">
          <DialogClose asChild className="w-full">
            <Badge
              onClick={() => nav("/services/lebanon")}
              variant="outline"
              className="text-md flex gap-2 justify-start items-center rounded-none dark:hover:bg-neutral-800 hover:bg-neutral-100 cursor-pointer"
            >
              <img
                src="/leb-flag.png"
                className="w-4"
                alt="leb-flag"
              />
              <span>LEBANON</span>
            </Badge>
          </DialogClose>
          <DialogClose asChild className="w-full">
            <Badge
              onClick={() => nav("/services/dubai")}
              variant="outline"
              className="text-md flex justify-start gap-2 items-center dark:hover:bg-neutral-800  rounded-none hover:bg-neutral-100 cursor-pointer"
            >
              <img
                src="/uae-flag.png"
                className="w-4"
                alt="leb-flag"
              />
              <span>DUBAI</span>
            </Badge>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
}
