import React, { useMemo } from "react";
import { wooProduct } from "./productInterface";
import { WooCommerce } from "../../../src/lib/api";
import { useInfiniteQuery, useQuery } from "react-query";
import Wishlist from "../../../src/components/Wishlist";
import AddToCart from "../../../src/components/addToCart";
import { Card } from "../../../src/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from "../../../src/components/ui/carousel";
import { Link, useNavigate } from "react-router-dom";
import { productCategoryFilter } from "../../../src/lib/utils";
import useWishlist from "../../../src/hooks/useWishlist";
import { Skeleton } from "../../../src/components/ui/skeleton";

export default function HomeProducts({
  title,
  categoryId,
  productId,
  similar,
}: {
  title: string;
  categoryId?: number;
  productId?: number;
  similar?: boolean;
}) {
  const nav = useNavigate();
  const { data: wishlist } = useWishlist();
  const wishlistArray = JSON.parse(
    window.localStorage.getItem("wishlistArray") as string
  );

  const { data: products, isLoading } = useQuery(
    ["homeProducts", categoryId, productId],
    async () => {
      const res: wooProduct[] = WooCommerce.get(
        `products/?per_page=100&page=1`
      )
        .then((response: any) => {
          if (similar) {
            const filteredProducts: wooProduct[] =
              response.data.filter((product: wooProduct) =>
                product.categories.some(
                  (category) =>
                    category.id === Number(categoryId) &&
                    product.id !== Number(productId)
                )
              );
            return filteredProducts;
          } else {
            return response.data.filter(
              (product: any) => product.featured
            );
          }
        })
        .catch((error: any) => {
          console.log("error getting products", error);
        });

      return res;
    }
  );

  if (products && products?.length < 1) {
    return <></>;
  }
  return !isLoading ? (
    <div className="flex-col flex gap-5 h-full">
      <h2
        className={`font-montserrat font-medium sm:text-2xl text-xl text-center lg:text-left ${
          !similar && "lg:pl-5"
        }`}
      >
        {title}
      </h2>
      <Carousel
        opts={{ loop: false, skipSnaps: true }}
        className="relative"
      >
        <CarouselContent className="h-full">
          {products?.map((product: wooProduct, index) => (
            <CarouselItem
              key={product.id}
              className="basis-100"
            >
              <Card
                className=" rounded-none font-montserrat w-56 snap-always snap-center justify-between relative flex flex-col gap-2 dark:border-neutral-800 dark:border border-0 shadow-none"
                key={index}
              >
                <div className="absolute top-4 right-4">
                  <Wishlist
                    productId={product.id}
                    productName={product.name}
                    wishlist={
                      wishlist ? wishlist : wishlistArray
                    }
                  />
                </div>
                {productCategoryFilter(product?.categories)
                  .slice(0, 1)
                  .map((category) => {
                    return (
                      <div
                        key={category.id}
                        className={
                          "w-full flex flex-col justify-between items-start gap-5"
                        }
                      >
                        <div
                          className="flex justify-center items-center w-full p-5 dark:bg-white cursor-pointer"
                          onClick={() =>
                            nav(
                              `/product/${category.id}/${category.name}/${product.id}/${product.slug}`
                            )
                          }
                        >
                          <img
                            src={
                              product?.images[0]?.src
                                ? product.images[0]?.src
                                : "/logo.png"
                            }
                            alt=""
                            className={
                              product?.images[0]?.src
                                ? "w-36 sm:w-44"
                                : "dark:invert w-36 sm:w-44"
                            }
                          />
                        </div>
                        <div className="pl-2 pr-2 w-full flex flex-col gap-3 text-center">
                          <Link
                            to={`/product/${category.id}/${category.name}/${product.id}/${product.slug}`}
                            className=" line-clamp-2 w-full font-medium text-sm"
                          >
                            {product?.name?.toUpperCase()}
                          </Link>
                          <span className="text-neutral-400 italic text-[12px] capitalize hidden">
                            {category.name.toLowerCase()}
                          </span>
                          <span className="text-neutral-400 italic text-[12px] hidden">
                            {product.tags[0]?.name}
                          </span>
                          <p className="line-clamp-1 w-full dark:text-neutral-200 text-neutral-900 text-[10px]">
                            {product.description.length > 0
                              ? product?.description.slice(
                                  3,
                                  -5
                                )
                              : "..."}
                          </p>
                          <p className="text-md font-bold grow">
                            {product?.price}$
                          </p>
                        </div>
                      </div>
                    );
                  })}
                <div className="pr-2 pl-2 w-full pb-2">
                  <AddToCart
                    id={String(product.id)}
                    quantity={"1"}
                    name={product.name}
                    categoryId={product.categories[0]?.id}
                    categoryName={
                      product.categories[0]?.name
                    }
                    hasVariations={
                      product?.attributes &&
                      product?.attributes.length > 0
                        ? true
                        : false
                    }
                  />
                </div>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        {products && products?.length > 6 && (
          <CarouselPrevious className="z-30 left-0 absolute h-full rounded-none opacity-25 hover:opacity-75 border-none sm:w-12 bg-neutral-200 dark:bg-neutral-900" />
        )}
        {products && products?.length > 6 && (
          <CarouselNext className="absolute z-30 right-0  h-full rounded-none opacity-25 hover:opacity-75 border-none sm:w-12 bg-neutral-200 dark:bg-neutral-900" />
        )}
      </Carousel>
    </div>
  ) : (
    <div className="flex justify-center gap-2 p-10 overflow-scroll no-scrollbar w-full">
      <div className="flex gap-2 flex-col items-center border h-80 justify-evenly p-5">
        <Skeleton className="w-32 h-32 rounded-full" />
        <Skeleton className="w-32 h-4" />
        <Skeleton className="w-24 h-3" />
        <Skeleton className="w-24 h-2" />
        <Skeleton className="w-24 h-2" />
      </div>
      <div className="flex gap-2 flex-col items-center border h-80 justify-evenly p-5">
        <Skeleton className="w-32 h-32 rounded-full" />
        <Skeleton className="w-32 h-4" />
        <Skeleton className="w-24 h-3" />
        <Skeleton className="w-24 h-2" />
        <Skeleton className="w-24 h-2" />
      </div>
      <div className="flex gap-2 flex-col items-center border h-80 justify-evenly p-5">
        <Skeleton className="w-32 h-32 rounded-full" />
        <Skeleton className="w-32 h-4" />
        <Skeleton className="w-24 h-3" />
        <Skeleton className="w-24 h-2" />
        <Skeleton className="w-24 h-2" />
      </div>
      <div className="flex gap-2 flex-col items-center border h-80 justify-evenly p-5">
        <Skeleton className="w-32 h-32 rounded-full" />
        <Skeleton className="w-32 h-4" />
        <Skeleton className="w-24 h-3" />
        <Skeleton className="w-24 h-2" />
        <Skeleton className="w-24 h-2" />
      </div>
      <div className="flex gap-2 flex-col items-center border h-80 justify-evenly p-5">
        <Skeleton className="w-32 h-32 rounded-full" />
        <Skeleton className="w-32 h-4" />
        <Skeleton className="w-24 h-3" />
        <Skeleton className="w-24 h-2" />
        <Skeleton className="w-24 h-2" />
      </div>
      <div className="flex gap-2 flex-col items-center border h-80 justify-evenly p-5">
        <Skeleton className="w-32 h-32 rounded-full" />
        <Skeleton className="w-32 h-4" />
        <Skeleton className="w-24 h-3" />
        <Skeleton className="w-24 h-2" />
        <Skeleton className="w-24 h-2" />
      </div>
      <div className="flex gap-2 flex-col items-center border h-80 justify-evenly p-5">
        <Skeleton className="w-32 h-32 rounded-full" />
        <Skeleton className="w-32 h-4" />
        <Skeleton className="w-24 h-3" />
        <Skeleton className="w-24 h-2" />
        <Skeleton className="w-24 h-2" />
      </div>
      <div className="flex gap-2 flex-col items-center border h-80 justify-evenly p-5">
        <Skeleton className="w-32 h-32 rounded-full" />
        <Skeleton className="w-32 h-4" />
        <Skeleton className="w-24 h-3" />
        <Skeleton className="w-24 h-2" />
        <Skeleton className="w-24 h-2" />
      </div>
      <div className="flex gap-2 flex-col items-center border h-80 justify-evenly p-5">
        <Skeleton className="w-32 h-32 rounded-full" />
        <Skeleton className="w-32 h-4" />
        <Skeleton className="w-24 h-3" />
        <Skeleton className="w-24 h-2" />
        <Skeleton className="w-24 h-2" />
      </div>
    </div>
  );
}
