import React from "react";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import {
  CheckCheck,
  Layers,
  PenIcon,
  PlusIcon,
} from "lucide-react";
import {
  category,
  subcategory,
} from "../../../../lib/interfaces";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";
import { useQuery, useQueryClient } from "react-query";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";

export default function SubcategoryDialog({
  subcategory,
}: {
  subcategory?: subcategory;
}) {
  const [subcategoryName, setSubcategoryName] =
    React.useState<string>(
      subcategory?.SubCategoryDesc || ""
    );
  const [subcategoryNotes, setsubCategoryNotes] =
    React.useState<string>(subcategory?.Notes || "");
  const [categoryId, setCategoryId] =
    React.useState<number>(subcategory?.CategoryID || 0);
  const reactQuery = useQueryClient();
  const newSubcategory: subcategory = {
    SubCategoryID: subcategory?.SubCategoryID || 0,
    SubCategoryDesc: subcategoryName,
    Notes: subcategoryNotes,
    CategoryID: categoryId,
  };
  const { data: categories } = useQuery(
    ["api/Category/Get_Categories"],
    async () => {
      const res = await fetch(
        `http://canadian-eye-center.com/api/Category/Get_Categories`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (!res.ok) {
        throw new Error(`HTTP error: ${res.status}`);
      }
      const receivedCategories: category[] =
        await res.json();
      return receivedCategories;
    }
  );

  const saveSubcategory = async (
    subcategory: subcategory
  ) => {
    try {
      const response = await fetch(
        `http://canadian-eye-center.com/api/SubCategory/Save_SubCategory`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            SubCategoryID: subcategory?.SubCategoryID || 0,
            CategoryID: categoryId,
            SubCategoryDesc: subcategoryName,
            Notes: subcategoryNotes,
          }),
        }
      );
      const data = await response.json();
      console.log(data);
      reactQuery.refetchQueries([
        "api/SubCategory/Get_SubCategories",
        categoryId,
      ]);
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        {subcategory ? (
          <Button
            className="flex justify-start gap-2"
            variant="ghost"
          >
            <PenIcon className="w-4 h-4 " />
          </Button>
        ) : (
          <Button className="flex justify-start gap-2">
            <PlusIcon className="w-4 h-4 " />
            Add Subcategory
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] flex flex-col justify-start">
        {subcategory ? (
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <Layers className="w-4 h-4" />
              Edit Subcategory
            </DialogTitle>
            <DialogDescription>
              Edit subcategory here. Click save when you're
              done.
            </DialogDescription>
          </DialogHeader>
        ) : (
          <DialogHeader>
            <DialogTitle className="flex items-center gap-2">
              <Layers className="w-4 h-4" />
              Add Subcategory
            </DialogTitle>
            <DialogDescription>
              Add subcategory here. Click save when you're
              done.
            </DialogDescription>
          </DialogHeader>
        )}
        <div className="flex flex-col gap-2">
          <Select
            onValueChange={(e) => {
              setCategoryId(Number(e));
            }}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select a category" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Categories</SelectLabel>
                {categories?.map((category: category) => (
                  <SelectItem
                    key={category.CategoryID}
                    value={String(category.CategoryID)}
                  >
                    {category.CategoryName}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <Input
            id="subcategoryId"
            className="focus:outline-neutral-900 "
            value={subcategoryName}
            onChange={(e) =>
              setSubcategoryName(e.target.value)
            }
          />
          <Textarea
            id="subcategoryNotes"
            className="col-span-3"
            value={subcategoryNotes}
            onChange={(e) =>
              setsubCategoryNotes(e.target.value)
            }
          />
        </div>
        <DialogFooter>
          <DialogClose>
            <Button
              className="flex justify-center items-center gap-2"
              onClick={() =>
                saveSubcategory(newSubcategory)
              }
            >
              <CheckCheck className="w-4 h-4" />
              Save Subcategory
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
